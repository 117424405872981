import { combineReducers } from 'redux';
// import settings from './settings/reducer';
import menu from './menu/reducer';
import authorization from './auth/reducer';
import dashboard from './dash/reducer';


const reducers = combineReducers({
  menu,
  authorization,
  dashboard
});

export default reducers;