import { createActions } from "redux-actions";

const {
  signIn,
  signInSuccess,
  signInError,
  signOut,
  signOutSuccess,
  signUp
} = createActions(
  'SIGN_IN',
  'SIGN_IN_SUCCESS',
  'SIGN_IN_ERROR',
  'SIGN_OUT',
  'SIGN_OUT_SUCCESS',
  'SIGN_UP'
);

export {
  signIn,
  signInSuccess,
  signInError,
  signOut,
  signUp,
  signOutSuccess
};