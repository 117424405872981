import { call, takeEvery, put } from 'redux-saga/effects';
import * as dashboardActions from './actions';
import { authRequest } from '../../requests/authRequest';
import { DataRangePayload, salesListPayload, orderListPayload, KPIPayload } from '../../constants/payloads'



function* kpiTableSaga({ payload }) {
    try {
        const response = yield call(authRequest, payload.requestData);
        if (response.status === 200) {
            yield put(dashboardActions.getKpiTableSuccess(response.data.body));
        } else {
            throw response;
        }
    } catch (error) {
        console.log('kpiTable Saga Error response', error.response);
        yield put(dashboardActions.getKpiTableFail(error.response.data.error));
    }
}

function* getStatSaga({ payload }) {
    try {
        const { didMount, requestData } = payload;
        const response = yield call(authRequest, { ...DataRangePayload, ...requestData });
        if (response.status === 200) {
            if (didMount) {
                yield put(dashboardActions.loadStatSuccess(response.data));
            } else {
                yield put(dashboardActions.getStatSuccess(response.data));
            }

        } else {
            throw response;
        }
    } catch (error) {
        console.log('getStat Saga Error response', error.response);
        yield put(dashboardActions.getStatFail(error.response.data.error));
    }
}

function* getOrdersSaga({ payload }) {
    try {
        const response = yield call(authRequest, { ...orderListPayload, ...payload.requestData });
        if (response.status === 200) {
            yield put(dashboardActions.getOrdersListSuccess(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        console.log('getOrders Saga Error response', error);
        yield put(dashboardActions.getStatFail(error.response.data.error));
    }
}

function* getSalesListSaga({ payload }) {
    try {
        const { didMount, requestData } = payload;
        const response = yield call(authRequest, { ...salesListPayload, ...requestData });
        if (response.status === 200) {
            if (didMount) {
                yield put(dashboardActions.loadSalesListSuccess(response.data));
            } else {
                yield put(dashboardActions.getSalesListSuccess(response.data));
            }


        } else {
            throw response;
        }
    } catch (error) {
        console.log('getSalesList Saga Error response', error.response);
        yield put(dashboardActions.getStatFail(error.response.data.error));
    }
}

function* getKPISaga({ payload }) {
    try {
        const response = yield call(authRequest, { ...KPIPayload, ...payload });
        if (response.status === 200) {
            let fields = response.data.fields.map(f => f.calculateCustomSummary ? { ...f, calculateCustomSummary: eval(f.calculateCustomSummary) } : { ...f })
            yield put(dashboardActions.getKpiSuccess({ ...response.data, fields }));
        } else {
            throw response;
        }
    } catch (error) {
        console.log('getKPI Saga Error response', error);
        yield put(dashboardActions.getKpiFail(error.response.data.error));
    }
}


export default function* dashWatcher() {
    yield takeEvery(dashboardActions.getKpiTable.toString(), kpiTableSaga);
    yield takeEvery(dashboardActions.getStat.toString(), getStatSaga);
    yield takeEvery(dashboardActions.getOrders.toString(), getOrdersSaga);
    yield takeEvery(dashboardActions.getSalesList.toString(), getSalesListSaga);
    yield takeEvery(dashboardActions.getKpi.toString(), getKPISaga);

}