import { handleActions } from 'redux-actions';
import * as authAction from './actions';

const initState = {
    status: "IDLE",
    loading: false,
    errorMessage: null,
    key: null,
    login: null
}

const authorization = handleActions(
    {
        [authAction.signIn]: (state, _action) => {
            return { ...state, loading: true };
        },
        [authAction.signInSuccess]: (state, { payload }) => {
            const { key, login } = payload;
            return { status: "SUCCESS", authError: null, loading: false, login, key };
        },
        [authAction.signInError]: (state, { payload }) => {
            return { status: "FAIL", loading: false, errorMessage: payload.error };

        },
        [authAction.signOutSuccess]: (state, _action) => {
            return { ...initState };

        },
    },
    initState
);

export default authorization;
