
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
import ColorSwitcher from './components/common/ColorSwitcher';
import { isMultiColorActive } from './constants/defaultValues';
import './App.scss';

const ViewMain = React.lazy(() =>
  import(/* webpackChunkName: "views" */ './views')
);
const ViewApp = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './views/app')
);
const ViewUser = React.lazy(() =>
  import(/* webpackChunkName: "views-user" */ './views/user')
);
const ViewError = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/error')
);

// console.log(eval(babel.transform('<ViewError/>')))

const AuthRoute = ({ component: Component, authStatus, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authStatus === 'SUCCESS' ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: '/user/login',
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

class App extends Component {

  render() {
    const { authStatus } = this.props;

    return (
      <div className="h-100">
        <React.Fragment>
          <NotificationContainer />
          {isMultiColorActive && <ColorSwitcher />}
          <Suspense fallback={<div className="loading" />}>
            <Router>
              <Switch>
                <AuthRoute
                  path="/app"
                  authStatus={authStatus}
                  component={ViewApp}
                />
                <Route
                  path="/user"
                  render={props => <ViewUser {...props} isAuth={authStatus === "SUCCESS"} />}
                />
                <Route
                  path="/error"
                  exact
                  render={props => <ViewError {...props} />}
                />
                <Route
                  path="/"
                  exact
                  render={props => <ViewMain {...props} />}
                />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </Suspense>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = ({ authorization }) => {
  const { status: authStatus } = authorization;
  return { authStatus };
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
